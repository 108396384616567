import React, {Component} from 'react';
import {connect} from "react-redux";
import {tokenId, userName, userRole} from '../../utilities/AuthService';
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'
import * as storyActions from "../../actions/Story";
import ReactToPrint from "react-to-print";



function ToTitleCase(props)
{
    let str = props.name  ;
    return str.replace(/\b\w/g, function (txt) { return txt.toUpperCase(); });
}



class Listentries extends Component {

    constructor(props) {
        super(props);
        this.FilterList=this.FilterList.bind(this)
        this.ReadMore=this.ReadMore.bind(this)
        this.Selection  = this.Selection.bind(this)
        this.state={
            filter: '',
            userrole:userRole().userrole,
            encid:tokenId().id,
        }

    }

    FilterList(e){
        this.setState({filter:e.target.value})
    }



    componentDidMount() {

        this.props.listallentries(this.state.encid);

    }


    ReadMore(e,id){
        console.log(id) ;
        window.location = "/readmore?id="+id+'&page=entries';

    }

    Selection(e){
        console.log(e.target.value) ;

    }







    render() {

        var lstories =  []  ;
        console.log(this.props.listentries.Entries.data) ;
        if (this.props.listentries.Entries.data !== undefined ){
            console.log("inside loop");
            console.log(this.props.listentries.Entries.data) ;
            if (this.state.filter !== "" ) {
                lstories = this.props.listentries.Entries.data.filter(
                    item => (item.fname.toLowerCase().search(this.state.filter.toLowerCase()) >= 0 )
                )

            }else{
                lstories =  this.props.listentries.Entries.data  ;

            }
        }


        return (
            <div className="page-content-holder">
                <Sidebar selection="entries" />
                <div className="tab-switching-panel-holder">
                    <div className="tab-switching-panel" id="users" >


                        <div className="intro-text">

                            <h4>Dear {userName().fname} {userName().lname},</h4>
                            <p>Welcome to the online judging of EAF Awards.</p>

                            <p>Thank you for volunteering your precious time to evaluate the work of aspiring journalists.</p>
                            <p>Clicking on the name of the entrant will take you to her/his article.</p>
                            <p>Though the basic criteria of judging will be quality/relevance of content, perspective, original thinking, expression, style and language, your unique eye and sensibility would be what matters the most.</p>
                            <p>Please rate the article based on the criteria given and then click "Submit & Continue Evaluation."</p>
                            <p>You can exit and continue judging whenever you want to. </p>

                            <h4>We request you to complete the judging on or before 24 December 2021.  </h4>
                            <p>Once again, thank you for your valuable contribution to the EAF Awards.</p>
                            <p>If you have questions with the judging process, feel free to write to <a href="mailto:swarupbr@gmail.com">swarupbr@gmail.com</a> or call 9846122376.</p>

                            <h4>2020 topic</h4>
                           {/* <p><strong>How Can We Save Kerala's Monsoon Dream-Season From Becoming A Recurring Nightmare?</strong></p>
                            <p>Every media vehicle has made its recommendations - visionary thinking, people-centric long-term planning and implementation, political will, integrating environment sustainability into everything - the list is endless. Kerala definitely needs a new sustainable model of development - and the answer perhaps lies in the middle ground between the two distinctly diverse schools of thought developing in this space. How deep can you dig? How high can you fly? How many planned conversations can you have? How interesting a perspective can you shed?</p>*/}

                            <p><strong>The Local Story Of A Global Catastrophe</strong></p>
                            <p>The pandemic exposed the underbelly of India's economy and revealed the precariousness of a large section of the population. As economic activity ceased, factories, shops, eateries and what not downed their shutters, leaving scores of workers without jobs.</p>
                            <p>Kerala being a rurban economy with no palpable rural-urban divide, we have a huge population of skilled, semi-skilled and unskilled workers across the State - teachers in unaided schools, hotel staff, fish vendors, newspaper vendors, labourers, domestic workers, petty traders, auto-rickshaw drivers, small business owners and so on. Their incomes plummeted - almost became negligible - as the State shutdown and people retreated into their homes. Other than broad figures of economic losses, we still have no idea how these people and their families survived without any source of income.</p>
                            <p>We invite you to tell their stories - either through the experience of an individual, a family, a sector, a trade or a panchayat. These could be stories of great courage, survival, tenacity, commitment and social responsibility. These stories need to be told. Because the COVID-19 story is not just about a large catastrophe captured in big numbers, but also the micro experiences of ordinary people and their crushed dreams. Thousands of small businessmen and women are also going through the pain of restarting in a digitally transformed world where the behaviour of their customers have changed dramatically.</p>
                            <p>While you track these intensely personal experiences, please remember the big picture and that one story is representative of a thousand similar stories that have not been recorded in history. It can be a singular voice or a collection of many voices, reported and narrated well to tell the biggest news event of this century.</p>



                        </div>

                        <div className="panel-options-head entries">
                            <h5>Entries</h5>
                            <ReactToPrint
                                trigger={() => <a className="btn print-btn icon-btn icon-btn--print" href="#">Print Entries</a>}
                                content={() => this.componentRef}
                            />
                        </div>
                        <div className="panel-options-content" >
                            <div className="scrollable table-wrapper">
                                <table className="user-table table-in"  ref={el => (this.componentRef = el)}>
                                    <thead>
                                    <tr>

                                        <th>Name</th>

                                        {/*<th>Score</th>*/}
                                        <th>Marks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { lstories.map((list,index) =>

<tr  key={`list-shortlisted-table-data-`+index}  className="show-click" onClick={(e) => this.ReadMore(e,list.storyid)}>


                                            <td>

                                                <label className="sl-no">{index+1}</label>
                                                <label className="inline-text">{list.fname} {list.lname}</label>
                                            </td>

                                            {/*<td>{list.remarks}</td>*/}
                                            <td>{list.marks}</td>

                                        </tr>
                                    )}
                                    </tbody>





                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );

    }
}


const mapStateToProps = state => ({
    listentries:state.StoryReducer.ListEntries,


});



export default  withRouter(connect(mapStateToProps, {
    listallentries:storyActions.listAllEntries
})(Listentries));