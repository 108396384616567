import {Stories} from '../services/story.services'
export const listallstories = (params) => dispatch => {

    let listallstories = Stories.listStories(params);
    console.log("fetch data") ;
    console.log(listallstories);
    listallstories.then(listallstories => {
        console.log(listallstories);
        dispatch({
            type: 'LIST_All_STORIES',
            listallstories:listallstories

        })
    })

};


export const listShortlistedstories = (params) => dispatch => {
    let listShortlistedstories = Stories.listShortlistedStories(params);
    console.log("fetch data") ;
    console.log(listShortlistedstories);
    listShortlistedstories.then(listShortlistedstories => {
        console.log(listShortlistedstories);
        dispatch({
            type: 'LIST_All_SHORTLISTS',
            listShortlistedstories:listShortlistedstories

        })
    })

};


export const listAllEntries = (encid) => dispatch => {

    console.log(encid);

    let listAllEntries = Stories.listAllEntries(encid);
    console.log("fetch data") ;
    console.log(listAllEntries);
    listAllEntries.then(listAllEntries => {
        console.log(listAllEntries);
        dispatch({
            type: 'LIST_All_ENTRIES',
            listAllEntries:listAllEntries

        })
    })

};


export const shortliststory = (params) => dispatch => {
    console.log(params);
    let shortliststory = Stories.shortlistingstory(params);
    console.log("fetch data") ;
    console.log(shortliststory);
    shortliststory.then(shortliststory => {
        console.log(shortliststory);
        if(shortliststory.data) {
            dispatch({
                type: 'SHORTLIST_STORY'
            })
        }
    })

};





export const readStory = (storyid, encid) => dispatch => {

    let currentstory = Stories.readstory(storyid, encid);
    console.log("fetch data") ;
    console.log(currentstory);
    currentstory.then(currentstory => {
        console.log(currentstory);
        dispatch({
            type: 'LIST_CURRENT_STORY',
            currentstory:currentstory

        })
    })

};

export const nextstory = (storyid, encid) => dispatch => {

    let nextstory = Stories.nextstory(storyid, encid);
    console.log("fetch data") ;
    console.log(nextstory);
    nextstory.then(nextstory => {
        console.log(nextstory);
        dispatch({
            type: 'LIST_NEXT_STORY',
            nextstory:nextstory

        })
    })

};

export const readCriteria = (params) => dispatch => {

    let allcriterias = Stories.readcriteria(params);
    console.log("fetch data") ;
    console.log(allcriterias);
    allcriterias.then(allcriterias => {
        console.log(allcriterias);
        dispatch({
            type: 'LIST_ALL_CRITERIA',
            allcriterias:allcriterias

        })
    })

};



export const readAssets = (storyid) => dispatch => {

    let allassets = Stories.readassets(storyid);
    console.log("fetch data") ;
    console.log(allassets);
    allassets.then(allassets => {
        console.log(allassets);
        dispatch({
            type: 'LIST_ALL_ASSETS',
            allassets:allassets

        })
    })

};



export const scoreCheck=(storyid,encid)=>dispatch=>{
    let scorecheck=Stories.scorecheck(storyid,encid)
    scorecheck.then(scorecheck => {

        if(scorecheck.data){
            dispatch({
                type: 'SCORE_UPDATES'


            })
        }



    })
};

export const allscoresCheck =(storyid,encid)=>dispatch=>{
    let totalscores= Stories.allscoresCheck(storyid,encid)
    totalscores.then(totalscores => {

        console.log(totalscores);

            dispatch({
                type: 'SCORE_CHECK',
                totalscores:totalscores


            })
    })
};

export const UpdateSCore = (storyid,encid,selectedOption,action) => dispatch => {
    return new Promise((resolve,reject) => {
        let updatescore = Stories.updatescore(storyid,encid,selectedOption,action);


        updatescore.then(updatescore => {
            console.log(action) ;
            console.log(updatescore.data) ;

            resolve(1);
            if(updatescore.data==1){
                dispatch({
                    type: 'SCORE_UPDATES',
                    updatescore : updatescore

                })
            }


        })

    })




};
export const assignStoryMarks = (storyid,marks) => dispatch => {
    return new Promise((resolve,reject) => {
        let assignMarks = Stories.assignmarks(storyid,marks,);

        assignMarks.then(assignMarks => {
            resolve(1);
            if(assignMarks.data==1){
                dispatch({
                    type: 'ASSIGN_STORY_MARKS',
                    assignMarks : assignMarks

                })
            }


        })

    })




};
