import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'
import * as storyActions from "../../actions/Story";



function ToTitleCase(props)
{
    let str = props.name  ;
    return str.replace(/\b\w/g, function (txt) { return txt.toUpperCase(); });
}



class Liststories extends Component {

    constructor(props) {
        super(props);
        this.FilterList=this.FilterList.bind(this)
        this.ReadMore=this.ReadMore.bind(this)
        this.Selection  = this.Selection.bind(this)
        this.state={
            filter: '',
            usershortlistids:[],
            selctedstory1:'',
            selctedstory2:'',
            selctedstory3:'',
            selctedstory4:'',
            selctedstory5:'',



        }

    }


    componentDidMount() {

        this.props.stories();

    }

    FilterList(e){

        this.setState({filter:e.target.value})

    }


    ReadMore(e,id){
        console.log(id) ;
        window.location = "/readmore?id="+id+'&page=stories';

    }

    Selection(e, index){

        const usershortlistids = [...this.state.usershortlistids];
        const presentIndex = usershortlistids.indexOf(e.target.value)
        if(presentIndex > 0) {
            usershortlistids.splice(presentIndex, 1);
        } else {
            usershortlistids.push(e.target.value);
        }
        this.setState({
            usershortlistids,

            [e.target.name]: e.target.value

        })


    }

    handleShortlist=()=>{

        console.log(this.state.usershortlistids);

        this.props.shortlisting(this.state.usershortlistids.toString())

    }







    render() {

        if(this.props.shortlistStorydata.Status===true){

            alert("Story shortlisted successfully")

            window.location = "/liststories" ;
        }
        console.log(this.props.listStorydata)
        var lstories =  []  ;
        console.log(this.props.listStorydata.Stories.data) ;
        if (this.props.listStorydata.Stories.data !== undefined ){
            console.log("inside loop");
            console.log(this.props.listStorydata.Stories.data) ;
            if (this.state.filter !== "" ) {
                lstories = this.props.listStorydata.Stories.data.filter(
                    item => (item.fname.toLowerCase().search(this.state.filter.toLowerCase()) >= 0 )
                )

            }else{
                lstories =  this.props.listStorydata.Stories.data  ;

            } 
        }


        return (
            <div className="page-content-holder">
                <Sidebar selection="stories" />
                <div className="tab-switching-panel-holder">
                    <div className="tab-switching-panel" id="users" >
                        <div className="panel-options-head">
                            <h5>Scoreboard</h5>

                        </div>

                        <div className="panel-options-content">
                            <div className="scrollable table-wrapper">
                                <table className="user-table table-in">
                                    <thead>
                                    <tr>

                                        <th>Name</th>
                                        <th>Judge1</th>
                                        <th>Judge2</th>
                                        <th>Judge3</th>
                                        <th>Judge4</th>
                                        <th>Judge5</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    { lstories.map((list,index) =>
                                        <tr key={`list-stories-table-data-`+index}>

                                            <td>
                                              {/*  <label className="cs-checkbox">
                                                    <input type="checkbox" name={`selctedstory${index+1}`} className="cs-checkbox-in" value={list.storyid} onChange={(e) => this.Selection(e, index)} />
                                                    <span className="cs-placeholder"></span>
                                                </label>*/}
                                                <label className="sl-no">{index+1}</label>
                                                <label className="inline-text">{list.fname} {list.lname} </label>
                                            </td>
                                            <td>{list.judge1 == 0 ? "--": list.judge1 }</td>
                                            <td>{list.judge2 == 0 ? "--": list.judge2}</td>
                                            <td>{list.judge3 == 0 ? "--": list.judge3}</td>
                                            <td>{list.judge4 == 0 ? "--": list.judge4}</td>
                                            <td>{list.judge5 == 0 ? "--": list.judge5}</td>
                                        </tr>
                                    )}
                                    </tbody>





                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );

    }
}


const mapStateToProps = state => ({
    listStorydata:state.StoryReducer.listStorydata,
    shortlistStorydata:state.StoryReducer.shortlistStory


});



export default  withRouter(connect(mapStateToProps, {
    stories:storyActions.listallstories,
    shortlisting:storyActions.shortliststory
})(Liststories));
