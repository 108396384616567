import React, {Component} from 'react';
import {connect} from "react-redux";
import * as registrationActions from "../../actions/Registration"
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'


function Displayrows(props){
    let displist = props.displist  ;

    return (
        <tbody>
        { displist.map((list,index) =>
        <tr key={`list-registration-table-data-`+index}>
            <td>

                <label className="sl-no">{index+1}</label>
                <label className="inline-text">{list.fname} {list.lname}</label>
            </td>
            <td>{list.age}</td>
            <td>{list.phone}</td>
            <td>{list.emailid}</td>
            <td className="date-text">{list.ijtbranch}</td>
        </tr>
        )}
        </tbody>

    );
}


class Listregistration extends Component {

    constructor(props) {
        super(props);
        this.FilterList=this.FilterList.bind(this)
        this.state={
            filter: '',


        }

    }

    componentDidMount() {

        this.props.registrations();

    }

    FilterList(e){

        this.setState({filter:e.target.value})

    }








    render() {


        var lregistrations =  []  ;
        console.log(this.props.listRegistrationdata.Registrations.data) ;
        if (this.props.listRegistrationdata.Registrations.data !== undefined ){
            console.log("inside loop");
            console.log(this.props.listRegistrationdata.Registrations.data) ;
            if (this.state.filter !== "" ) {
                lregistrations = this.props.listRegistrationdata.Registrations.data.filter(
                    item => (item.fname.toLowerCase().search(this.state.filter.toLowerCase()) >= 0 || item.emailid.toLowerCase().search(this.state.filter.toLowerCase())  >= 0 ||  item.phone.search(this.state.filter)  >= 0 )
                )

            }else{
                lregistrations =  this.props.listRegistrationdata.Registrations.data  ;

            }
        }


        return (
                <div className="page-content-holder">
                    <Sidebar selection="registrations" />
                           <div className="tab-switching-panel-holder">
                                <div className="tab-switching-panel" id="users" >
                    <div className="panel-options-head">
                        <h5>List Registrations</h5>
                       {/* <div className="search-option-block">
                            <input type="text" name="search-in" placeholder="Search users by Name, Role" className="search-input-in" onChange = {this.FilterList} />
                                <span className="sprite-in icon--search in-search-box"></span>
                        </div>*/}
                        <div className="user-options-block">

                        </div>
                    </div>

                    <div className="panel-options-content">
                        <div className="scrollable table-wrapper">
                            <table className="user-table table-in">
                                <thead>
                                <tr>

                                    <th>Name</th>
                                    <th>Age</th>
                                    <th>Phone Number</th>
                                    <th>Email Address</th>
                                    <th>IJT Branch</th>
                                </tr>
                                </thead>


                                { lregistrations.length > 0 ? (

                                    <Displayrows displist = {lregistrations} length={lregistrations.length} />


                                ) : 'Loading...' }



                            </table>
                        </div>
                    </div>
                </div>
                    </div>

                </div>

        );

    }
}



const mapStateToProps = state => ({
    listRegistrationdata:state.RegistrationReducer.listRegistrationdata,


});



export default  withRouter(connect(mapStateToProps, {
    registrations:registrationActions.listallregistrations
})(Listregistration));
