import axios from 'axios'
import {API_PATH} from '../constants/index'


export const Registrations = {
    listUsers


};


function listUsers(){
    return axios.get(API_PATH + 'admin/listregistrations')
}
